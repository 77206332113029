import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
// MetisMenu

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

import logo from "./icon2.png"

//i18n
import { withTranslation } from "react-i18next"
const addCampainsArray = [
  "/campaigns/Feedback",
  "/campaigns/Other",
  "/campaigns/Reminder",
  "/campaigns/Sales",
  "/campaigns/Service",
]
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { Button } from "reactstrap"

const Header = props => {
  const [search, setsearch] = useState(false)
  const { id, name } = useParams()
  const [path, setPath] = useState("")

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  useEffect(() => {
    console.log(window.location)
    console.log(window.location.pathname)
    console.log(window.location.pathname.search("") >= 0)
    setPath(window.location.pathname)
  }, [window.location.pathname])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>

              {/* <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link> */}
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form> */}
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className=" d-flex ">
              {sessionStorage.getItem("authUser") && (
                <Button
                  className="btn px-3 text-info rounded-pill bg-info-subtle bg-gradient align-self-center border-0 me-2"
                  onClick={() => {
                    localStorage.setItem(
                      "authUser",
                      sessionStorage.getItem("authUser")
                    )
                    localStorage.setItem("T", "O")
                    localStorage.setItem("name", sessionStorage.getItem("name"))
                    sessionStorage.clear()
                    window.location.reload()
                  }}
                >
                  Back to Admin
                </Button>
              )}
              {addCampainsArray.includes(path) && (
                <Link
                  to={"/tag/" + id}
                  className="btn px-3 text-primary rounded-pill bg-primary-subtle bg-gradient align-self-center me-3"
                >
                  Stages & Transitions
                </Link>
              )}
              {addCampainsArray.includes(path) && (
                <Link
                  to="/create"
                  className="btn px-3 text-primary rounded-pill bg-primary-subtle bg-gradient align-self-center"
                >
                  <i className="bx bx-plus" /> Add New
                </Link>
              )}
              {path.search("/campaigns/details/") >= 0 && (
                <Link
                  to={`/leads/${id}/${name}`}
                  className="btn px-3 text-primary rounded-pill bg-primary-subtle bg-gradient align-self-center me-2"
                >
                  <i className="bx bx-phone" /> Leads
                </Link>
              )}
              {path.search("/campaigns/details/") >= 0 && (
                <Link
                  to={`/campaigns/edit/${id}`}
                  className="btn px-3 text-primary rounded-pill bg-primary-subtle bg-gradient align-self-center"
                >
                  <i className="bx bx-edit-alt" /> Edit Campaigns
                </Link>
              )}
            </div>

            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
